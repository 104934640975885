<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
      >
        <div
            class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
          >
            <b-form-input
              id="voucherNoFilter"
              v-model="voucherNoFilter"
              placeholder="Voucher No"
            />
        </div>
      </div>

      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
        v-if="!messSecretary && !messReceptionist"
      >
      <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-input
            id="messNameFilter"
            v-model="messNameFilter"
            placeholder="Mess name"
          />
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-input
            id="cityNameFilter"
            v-model="cityNameFilter"
            placeholder="City name"
          />
        </div>

        <!-- <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-input
            id="managingHqNameFilter"
            v-model="managingHqNameFilter"
            placeholder="Search by managing hq name"
          />
        </div> -->
      </div>
    </div>
    
    <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-2"
    >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mb-1 mr-3"
        >
         <div
          class="d-flex justify-content-center align-items-center flex-column"
        >
         <label for="creationDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Created From:</label>
          <b-input-group>
            <b-form-input
              id="creationDateFromInput"
              v-model="creationDateFromFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="creationDateFrom"
                v-model="creationDateFromFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
        <div
            class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
          >
          <div
          class="d-flex justify-content-center align-items-center flex-column"
          >
          <label for="creationDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Created To:</label>
          <b-input-group>
            <b-form-input
              id="creationDateToInput"
              v-model="creationDateToFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="creationDateTo"
                v-model="creationDateToFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>          
        </div>
      </div>    
     </div>

     <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-2"
    >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mb-1 mr-3"
        >
         <div
          class="d-flex justify-content-center align-items-center flex-column"
        >
         <label for="startDateInput" class="text-nowrap h5 mr-5 p-50 mb-25">Booking Checkin Date (From):</label>
          <b-input-group>
            <b-form-input
              id="startDateFromInput"
              v-model="startDateFromFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="startDateFrom"
                v-model="startDateFromFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
        <div
            class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
          >
          <div
          class="d-flex justify-content-center align-items-center flex-column"
          >
          <label for="creationDateFromInput" class="text-nowrap h5 mr-5 p-50 mb-25">Booking CheckIn Date (To):</label>
          <b-input-group>
            <b-form-input
              id="checkinDateToInput"
              v-model="startDateToFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="checkinDateTo"
                v-model="startDateToFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group> 
        </div>
      </div>    
     </div>

     <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-2"
    >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mb-1 mr-3"
        >
         <div
          class="d-flex justify-content-center align-items-center flex-column"
        >
         <label for="endDateInput" class="text-nowrap h5 mr-5 p-50 mb-25">Booking Checkout Date (From):</label>
          <b-input-group>
            <b-form-input
              id="endDateFromInput"
              v-model="endDateFromFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="endDateInput"
                v-model="endDateFromFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
        <div
            class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
          >
          <div
          class="d-flex justify-content-center align-items-center flex-column"
          >
          <label for="endDateToInput" class="text-nowrap h5 mr-5 p-50 mb-25">Booking Checkout Date (To):</label>
          <b-input-group>
            <b-form-input
              id="endDateToInput"
              v-model="endDateToFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="endDateTo"
                v-model="endDateToFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>          
        </div>
      </div>    
     </div>

    <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="statusOptions">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedStatusFilter"
              :options="statusOptions"
              autoscroll
              :clearable="true"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 190px"
              placeholder="Select Booking Status"
              :disabled="allPaid || noCancelledUnpaid"
            ></v-select>
          </b-form-group>
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="PaymentOptions">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedPaymentFilter"
              :options="PaymentOptions"
              autoscroll
              :clearable="true"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 190px"
              placeholder="Select Payment Status"
              :disabled="allPaid || noCancelledUnpaid"
            ></v-select>
          </b-form-group>
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-col>
              <b-form-checkbox
                id="noCancelledUnpaid"
                v-model="noCancelledUnpaid"
                name="noCancelledUnpaid"
              >
              No Cancelled/Unpaid
              </b-form-checkbox>
          </b-col>
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-col>
              <b-form-checkbox
                id="allPaid"
                v-model="allPaid"
                name="allPaid"
              >
              All paid
              </b-form-checkbox>
          </b-col>
        </div>
      </div>
    </div>
    <!-- <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-2"
    >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mb-1 mr-3"
        >
          <b-input-group>
            <b-form-input
              id="checkinDateFromInput"
              v-model="startDateFromFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="checkinDateFrom"
                v-model="startDateFromFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div
            class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
          >
          <b-input-group>
            <b-form-input
              id="checkinDateToInput"
              v-model="startDateToFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="checkinDateTo"
                v-model="startDateToFilter"
                button-variant="primary"
                button-only
                right
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>          
        </div>
    </div> -->
   

      <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="sortByField">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedSortByField"
              :options="sortByFieldOptions"
              autoscroll
              :clearable="true"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 190px"
              placeholder="Select Field"
            ></v-select>
          </b-form-group>
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="sortingOrder">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedSortingOption"
              :options="sortingOrderOptions"
              autoscroll
              :clearable="false"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 190px"
              placeholder="Sorting Order"
            ></v-select>
          </b-form-group>
        </div>
         <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="sortByField">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedCheckoutStatus"
              :options="CheckoutStatusOptions"
              autoscroll
              :clearable="false"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 190px"
              placeholder="Select Checkout Status"
            ></v-select>
          </b-form-group>
        </div>
    </div>
    <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-button variant="primary" pill @click="filter">
          <feather-icon icon="SearchIcon" class="mr-50" />
          <span class="align-middle">Search</span>
        </b-button>
      </div>

      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-button variant="primary" pill @click="search">
          <feather-icon icon="RefreshCwIcon" class="mr-50" />
          <span class="align-middle">Refresh</span>
        </b-button>
      </div>
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
      >
        <b-button variant="primary" pill @click="downloadXLS">
          <feather-icon icon="DownloadIcon" class="mr-50" />
          <span class="align-middle">Download Excel</span>
        </b-button>
      </div>
    </div>

    <b-card>
      <b-table
        responsive
        :fields="fields"
        :items="bookings"
        details-td-class="m-0 p-0"
        :tbody-tr-class="rowClass"
        small
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by_data ? row.item.created_by_data.user : "" }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by_data ? row.item.updated_by_data.user : "" }}
        </template>
        <template #cell(checked_in)="row">
          <template v-if="row.item.checked_in">
            <feather-icon icon="CheckIcon" class="mr-50" />
          </template>
          <template v-else>
            <feather-icon icon="XIcon" class="mr-50" />
          </template>
        </template>
        <template #cell(checked_out)="row">
          <template v-if="row.item.checked_out">
            <feather-icon icon="CheckIcon" class="mr-50" />
          </template>
          <template v-else>
            <feather-icon icon="XIcon" class="mr-50" />
          </template>
        </template>
        <template #cell(manage)="row">
          <div class="d-flex align-items-center">
            <template v-if="!row.item.checked_in && row.item.status==2 && row.item.payment_status==2">
              <b-button
                variant="info"
                pill
                size="sm"
                class="mr-1 text-truncate"
                :disabled="!isCheckinButtonEnabled(row.item.checkin_enable_time)"
                @click="CheckedInBooking(row.item)"
              >
                Check In
              </b-button>
            </template>
            <template v-if="!row.item.checked_out && row.item.checked_in">
              <b-button
                variant="danger"
                pill
                size="sm"
                class="mr-1 text-truncate"
                :disabled="!isCheckoutButtonEnabled(row.item.checkout_enable_time)"
                @click="CheckedOutBooking(row.item)"
              >
                Check Out
              </b-button>
            </template>
            <template v-if="!row.item.checked_out && row.item.checked_in">
              <b-button
                variant="danger"
                pill
                size="sm"
                class="mr-1 text-truncate"
                :disabled="!isEarlyCheckoutButtonEnabled(row.item.max_early_checkout_enable_time)"
                @click="earlyCheckout(row.item)"
              >
                Early Check Out
              </b-button>
              <b-modal
                id="earlyCheckout"
                v-model="earlyCheckoutModal"
                title="Early Checkout Refund"
                @hide="resetearlyCheckoutModal"
                hide-footer
              >
              <div>
              <h1 class="text-center">Summary</h1>
                <hr />
                 <div>
                <h5 class="text-danger">
                  Ensure Accurate / correct Bank Details
                </h5>
              </div>
              <hr />
                <validation-observer ref="earlyCheckoutFormValidation">
                  <b-form @submit.prevent>
                      <b-form-group
                        label-for="accountTitle"
                        :state="accountTitle ? true : false"
                      >
                        <h4>Enter your Account Title [Account Holder Name]</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="accountTitle" :rules="{ required: true, regex: /^[A-Z0-9@,. ''/_-]*$/}">
                              <b-form-input
                                id="accountTitle"
                                v-model="accountTitle"
                                placeholder="Enter Account Title"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your account title.
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group
                        label-for="bank"
                        :state="selectedBank ? true : false"
                      >
                        <h4>Select your Bank Name</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="bank" :rules="{ required: true }">
                              <v-select
                                id="bank"
                                label="name"
                                v-model="selectedBank"
                                :options="banks"
                                placeholder="Select Bank"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please select a bank.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    <div>
                      <b-form-group
                        label-for="iban"
                        :state="iban ? true : false"
                      >
                        <h4>Enter your IBAN Number</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="iban" :rules="{ required: !checkType, regex: /^PK[A-Z0-9]{22}$/ }">
                              <b-form-input
                                id="iban"
                                v-model="iban"
                                placeholder="Enter IBAN Number"
                                :state="errors.length > 0 ? false : null"
                              />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your IBAN number.
                        </b-form-invalid-feedback>
                      </b-form-group>                  
                    </div>
                  </b-form>
                </validation-observer>
              </div>

              <hr />
              <div
                class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
              >
                <div
                  class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
                >
                  <b-button @click="goBack" variant="secondary" class="pl-2 pr-2"
                    >Back</b-button
                  >
                </div>

                    <div
                      class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
                    >
                      <b-button
                        @click="earlyCheckoutConfirmation()"
                        variant="danger"
                        :disabled="EarlyCheckoutButtonDisabled"
                        class="pl-2 pr-2"
                        >Confirm</b-button
                      >
                    </div>
                  </div>
             </b-modal>
            </template>
            <template v-if="(row.item.status !== 3) && (!row.item.checked_in) && ((row.item.cancellation_charges.hours_left > 0) || (row.item.cancellation_charges.minutes_left > 0) || (row.item.cancellation_charges.seconds_left > 0)) && (row.item.created_by == getLoggedInUser.id)">
              <template v-if="hasPermission('booking_cancel')">
                <b-button
                  variant="danger"
                  pill
                  size="sm"
                  class="mr-1 text-truncate"
                  @click="ConfirmCancellation(row.item)"
                >
                  Cancel Booking
                </b-button>                
              </template>
              <b-modal
                id="bookingCancellation"
                v-model="showbookingCancellationModal"
                title="Cancellation Charges"
                @hide="resetbookingCancellationModal"
                hide-footer
          >
            <div>
              <h1 class="text-center">Summary</h1>
              <hr />
              <div>
                <h4>
                  Cancellation Fee : {{ cancellationAmount }}
                </h4>
                <h4>
                  Hours left until 1600 hours check-in date:
                  {{
                    cancellationTime > 0
                      ? cancellationTime
                      : 0
                  }}
                </h4>
                <h4>
                  Hours left until 1200 hours check-in date:
                  {{
                    cancellationTime1200
                  }}
                </h4>
                <h4>
                  Percentage Deduction :
                  {{ cancellationPercentage }}
                </h4>
                <hr />
                <validation-observer ref="paymentMethodFormValidation">
                  <b-form @submit.prevent>
                      <b-form-group
                        label-for="accountTitle"
                        :state="accountTitle ? true : false"
                      >
                        <h4>Enter your Account Title [Account holder Name]</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="accountTitle" :rules="{ required: true, regex: /^[A-Z0-9@,. ''/_-]*$/}">
                              <b-form-input
                                id="accountTitle"
                                v-model="accountTitle"
                                placeholder="Enter Account Title"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your account title.
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group
                        label-for="bank"
                        :state="selectedBank ? true : false"
                      >
                        <h4>Select your Bank Name</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="bank" :rules="{ required: true }">
                              <v-select
                                id="bank"
                                label="name"
                                v-model="selectedBank"
                                :options="banks"
                                placeholder="Select Bank"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please select a bank.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    <!-- <b-form-group label-for="checkType">
                    <template #label>
                      Choose <span class="text-danger">*</span>
                    </template>
                    <b-form-radio-group id="checkType" v-model="checkType">
                      <b-form-radio v-model="checkType" :value="true">
                        Account Number
                      </b-form-radio>
                      <b-form-radio v-model="checkType" :value="false">
                        IBAN
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group> -->
                    <!-- <div v-if="checkType">
                      <b-form-group
                        label-for="accountNumber"
                        :state="accountNumber ? true : false"
                      >
                        <h4>Enter your Account Number</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="accountNumber" :rules="{ required: checkType, regex: /^[0-9]*$/ }">
                              <b-form-input
                                id="accountNumber"
                                v-model="accountNumber"
                                placeholder="Enter Account Number"
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your account number.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div> -->
                    <div>
                      <b-form-group
                        label-for="iban"
                        :state="iban ? true : false"
                      >
                        <h4>Enter your IBAN Number</h4>
                        <b-row>
                          <b-col>
                            <validation-provider #default="{ errors }" name="iban" :rules="{ required: !checkType, regex: /^PK[A-Z0-9]{22}$/ }">
                              <b-form-input
                                id="iban"
                                v-model="iban"
                                placeholder="Enter IBAN Number"
                                :state="errors.length > 0 ? false : null"
                              />
                                  <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>
                          Please enter your IBAN number.
                        </b-form-invalid-feedback>
                      </b-form-group>                  
                    </div>
                  </b-form>
                </validation-observer>
              </div>

              <hr />
              <div
                class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
              >
                <div
                  class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
                >
                  <b-button @click="back" variant="secondary" class="pl-2 pr-2"
                    >Back</b-button
                  >
                </div>

                    <div
                      class="d-flex justify-content-center align-items-center flex-nowrap mr-2"
                    >
                      <b-button
                        @click="cancelPaidBooking(row.item)"
                        variant="danger"
                        :disabled="CancelButtonDisabled"
                        class="pl-2 pr-2"
                        >Cancel Booking</b-button
                      >
                    </div>
                  </div>

              <!--  -->
            </div>
              </b-modal>
            </template>
          </div>
        </template>
      </b-table>
      <b-row>
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>

      <div
        class="d-flex justify-content-center align-items-center flex-nowrap m-2"
      >
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </div>

    </b-card>
    <booking-create-modal
      :booking="booking"
      @modalClosed="onModalClosed"
      :showModal="visible"
      :key="`create-${bookingCreateModalCount}`"
    />
    <check-in-confirmation-modal
      :booking="booking"
      @modalClosed="onModalClosed"
      :showModal="visible"
      :key="`checkin-${CheckInModalCount}`"
    />
    <AlliedChargesModal
    @onmodalCloded="onModalClosed"
    :key="`view-${alliedChargesModalCount}`"/>

    <CancellationGuidelines
      @modalClosed="onModalClosed"
      :showModal="visible"
      :key="`cancel-${cancellationGuidelineModalCount}`"
    />
    <EarlyCheckInModal
    @onmodalCloded="onModalClosed"
    :key="`early-${alliedChargesModalCount}`"/>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import BookingCreateModal from "@/components/booking/admin/BookingCreateModal.vue";
import CheckInConfirmationModal from "@/components/booking/admin/CheckInConfirmationModal.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, max } from "@validations";
import AlliedChargesModal from "@/components/booking/admin/AlliedChargesModal.vue";
import CancellationGuidelines from "@/components/booking/admin/CancellationGuidelines.vue";
import EarlyCheckInModal from "@/components/booking/admin/EarlyCheckInModal.vue";
export default {
  components: {
    BookingCreateModal,
    CheckInConfirmationModal,
    ValidationProvider,
    ValidationObserver,
    AlliedChargesModal,
    CancellationGuidelines,
    EarlyCheckInModal,
  },
  data() {
    return {
      fields: [
        { key: "manage", label: "Manage" },
        { key: "voucher_no", label: "Voucher Number" },
        { key: "created_at", label: "Created At" },
        { key: "customer.name", label: "Customer Name" },
        { key: "customer.cnic", label: "Customer CNIC" },
        { key: "customer.mobile", label: "Customer Mobile" },
        { key: "rooms.length", label: "Rooms" },
        { key: "standard", label: "Standard" },
        { key: "oneRoomSuite", label: "One Room Suite" },
        { key: "twoRoomSuite", label: "Two Room Suite" },
        { key: "mess_data.name", label: "Mess Name" },
        { key: "mess_data.city_name", label: "City Name" },
        {
          key: "mess_data.managing_hq_name",
          label: "Managing HQ Name",
        },
        { key: "total_payment", label: "Total Payment" },
        { key: "start_date_time", label: "From" },
        { key: "end_date_time", label: "To" },
        { key: "status_name", label: "Status" },
        { key: "payment_status_name", label: "Payment Status" },
        { key: "checked_in", label: "Checked In" },
        { key: "checked_out", label: "Checked Out" },
        // { key: "created_at", label: "Created At" },
        // { key: "created_by_data", label: "Created By" },
        // { key: "updated_at", label: "Last updated" },
        // { key: "updated_by_data", label: "Updated By" },
      ],
      currentPage: 1,
      perPage: 0,
      totalDataLength: 0,
      totalItems: 0,
      booking: null,
      bookings: [],
      bookingCreateModalCount: 0,
      CheckInModalCount: 0,
      visible: false,
      customerNameFilter: "",
      customerCnicFilter: "",
      roomNameFilter: "",
      messNameFilter: "",
      // hotelNameFilter: "",
      cityNameFilter: "",
      managingHqNameFilter: "",
      isBusy: false,
      allowEdit: true,
      selectedStatusFilter: null,
      statusOptions: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Confirmed" },
        { id: 3, name: "Cancelled" },
        { id: 4, name: "Blocked" },
      ],
      PaymentOptions: [
        { id: 1, name: "Not Paid" },
        { id: 2, name: "Paid" },
        { id: 3, name: "Refund Pending" },
        { id: 4, name: "Refunded" },
      ],
      voucherNoFilter: "",
      creationDateFromFilter: "",
      creationDateToFilter: "",
      startDateFromFilter: "",
      startDateToFilter: "",
      endDateFromFilter: "",
      endDateToFilter: "",
      // startDateFilter: "",
      // endDateFilter: "",
      selectedPaymentFilter: "",
      checkType: true,
      selectedBank: null,
      accountTitle: "",
      accountNumber: "",
      iban: "",
      bookings: [],
      Amount: "",
      Time: "",
      Percentage: "",
      cancellation_charges: [],
      showbookingCancellationModal: false,
      banks:[],
      alliedChargesModalCount: 0,
      CheckoutStatusOptions: [
        { id: 1, name: "Not Checked Out" },
        { id: 2, name: "Early Checkout" },
        { id: 3, name: "Normal Checkout" },
        { id: 4, name: "Late Checkout" },
      ],
      selectedCheckoutStatus: null,
      selectedSortByField: null,
      sortingOrderOptions: [
        {
          id: 1,
          name: "Ascending",
          value: ""
        },        
        {
          id: 2,
          name: "Descending",
          value: "-"
        },
      ],
      sortByFieldOptions: [
        {
          id: 1,
          name: "Voucher Number",
          field_name: 'voucher_no',
        },
        {
          id: 2,
          name: "Creation Date",
          field_name: 'created_at',
        },
        {
          id: 3,
          name: "From Date",
          field_name: 'start_date_time',
        },
        {
          id: 4,
          name: "To Date",
          field_name: 'end_date_time',
        }
      ],
      selectedSortingOption: null,
      messSecretary: false,
      messReceptionist: false,
      noCancelledUnpaid: false,
      allPaid: true,
      earlyCheckoutModal: false,
      cancellationGuidelineModalCount: 0,
      earlyCheckInModalCount: 0,
      visible: false,
      cancellationAmount: 0,
      cancellationTime:0,
      cancellationTime1200: 0,
      cancellationPercentage:0,
      systemDateTime: null,
    };
  },
  mixins: [util],
  async mounted() {
    await this.fetchSystemTime()
    await this.fetchBanks();
    await this.fetchPaginatedData();
    this.selectedSortingOption = this.sortingOrderOptions[0]
    if(this.getLoggedInUser.mess_secretary){
      this.messSecretary = true
    }
    else if(this.getLoggedInUser.clerk){
      this.messReceptionist = true
    }
    this.systemDateTime = this.getSystemTime
  },
  methods: {
    ...mapActions({
      getBookings: "appData/getBookings",
      updateBooking: "appData/updateBooking",
      getBookingTypes: "appData/getBookingTypes",
      delete: "appData/deleteBooking",
      getBanksUnpaginated: "appData/getBanksUnpaginated",
      CancelBooking: "appData/CancelBooking",
      // updateBookingAttribute: "appData/updateBookingAttribute",
      createExcel: "appData/createExcel",
      checkoutBooking: "appData/checkoutBooking",
      fetchSystemTime: "appData/fetchSystemTime",
    }),
    rowClass(item, type) {
      const danger = "table-danger";
      if (!(this.getLoggedInUser.role_data.code_name == 'su') && !(this.getLoggedInUser.role_data.code_name == 'bk__agm')) {
        return;
      }
      if (!item || type !== "row") {
        return;
      }
      if(parseFloat(item.total_payment) !== parseFloat(item.calculated_total_price)){
        return danger
      }
      else{
        return
      }
    },
     alliedCharges() {
      this.alliedChargesModalCount +=1;
      this.$nextTick(()=>{
        this.$bvModal.show("allied-charges-modal")
      })
    },
    formatBookings(bookings) {
      return bookings.map(booking => {
        const standardCount = booking.rooms.filter(room => room.room_type_name === 'Standard').length;
        const oneRoomSuiteCount = booking.rooms.filter(room => room.room_type_name === 'One Room Suite').length;
        const twoRoomSuiteCount = booking.rooms.filter(room => room.room_type_name === 'Two Room Suite').length;

        return {
          ...booking,
          standard: standardCount,
          oneRoomSuite: oneRoomSuiteCount,
          twoRoomSuite: twoRoomSuiteCount,
        };
      });
    },
    isCheckinButtonEnabled(checkinEnableTime) {
        var systemDateTime  = this.systemDateTime
        const currentDate = new Date(systemDateTime);
        const checkinEnableTimeObj = new Date(checkinEnableTime);
        return currentDate >= checkinEnableTimeObj;
    },
    isCheckoutButtonEnabled(checkoutEnableTime) {
        var systemDateTime  = this.systemDateTime
        const currentDate = new Date(systemDateTime);
        let checkoutDateTimeObj = new Date(checkoutEnableTime);
        return currentDate >= checkoutDateTimeObj;
    },
    isEarlyCheckoutButtonEnabled(maxEarlyCheckoutEnableTime) {
        var systemDateTime  = this.systemDateTime
        const currentDate = new Date(systemDateTime);
        let maxEarlyCheckoutDateTimeObj = new Date(maxEarlyCheckoutEnableTime);
        return currentDate < maxEarlyCheckoutDateTimeObj;
    },
    async fetchBanks() {
      try {
        let res = await this.getBanksUnpaginated({});
        this.banks = res.data;
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    },

    async CheckedInBooking(booking) {
      this.booking = booking;
      this.CheckInModalCount += 1;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("check-in-confirmation-modal");
      });
    },

    async CheckedOutBooking(booking) {
      this.booking = booking;
      // if (!booking.checked_in) {
      //   this.$swal({
      //     title: "Cannot check out",
      //     text: "Please check in before checking out.",
      //     icon: "error",
      //   });
      //   return;
      // }

      const payload = {
        booking_id: this.booking.id,
        checked_out: true,
      };

      try {
        const res = await this.checkoutBooking(payload);
        if (res.status === 200) {
          this.$set(booking, "checked_out", true);
          this.$swal({
            title: "Checked Out",
            icon: "success",
          });
        }
      } catch (error) {
        console.error("Error checking out booking:", error);
      }
    },
    async earlyCheckout(booking) {
      this.booking = booking
      this.earlyCheckoutModal = true
    },

    async filter() {
      this.currentPage = 1;
      await this.search();
    },
    async search() {
      this.isBusy = true;
      await this.fetchPaginatedData();
      this.isBusy = false;
    },
    createBooking() {
      this.bookingCreateModalCount += 1;
      this.visible = true;
      this.$nextTick(() => {
        this.$bvModal.show("booking-create-modal");
      });
    },

    //HARD DELETE
    async deleteBooking(booking) {
      try {
        this.$swal({
          title: "Are you sure?",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            try {
              const res = await this.delete({
                pk: booking.id,
              });
              if (res.status === 204) {
                this.$swal({
                  title: "Booking deleted successfully",
                  icon: "success",
                });
                this.$nextTick(() => {
                  this.search();
                });
                this.$emit("modalClosed");
              }
            } catch (error) {
              this.show = false;
              if (error.response.status == 500) {
                // this.displayError("Kindly make sure this mess does not have any dependencies");
                this.$swal({
                  title:
                    "Kindly make sure this booking does not have any dependencies",
                  icon: "error",
                });
              } else {
                this.displayError(error);
              }
            }
          } else {
            this.show = false;
          }
        });
      } catch (error) {
        this.displayError(error);
      }
    },

    //SOFT DELETE
    // deleteBooking(booking) {
    //   try {
    //     this.$swal({
    //       title:
    //         "Are you sure? This booking will be deleted.",
    //       icon: "warning",
    //       showCloseButton: true,
    //       showCancelButton: true,
    //       confirmButtonText: "Confirm",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //         cancelButton: "btn btn-danger ml-1",
    //       },
    //       buttonsStyling: false,
    //     }).then(async (result) => {
    //       if (result.value) {
    //         try {
    //           const res = await this.updateBooking({
    //             payload: {
    //               is_delete: true,
    //               updated_by: this.getLoggedInUser.id,
    //             },
    //             pk: booking.id,
    //           });
    //           let response_string = "Booking deleted successfully";
    //           let response_msg = response_string;
    //           if (res.data.response_msg) {
    //             response_msg = response_msg.concat(res.data.response_msg);
    //           }
    //           if (res.status === 200) {
    //             this.$swal({
    //               title: response_msg,
    //               icon: "success",
    //             });
    //             this.$nextTick(() => {
    //               this.fetchPaginatedData();
    //             });
    //             this.$emit("modalClosed");
    //           }
    //         } catch (error) {
    //           this.show = false;
    //           this.displayError(error);
    //         }
    //       } else {
    //         this.show = false;
    //       }
    //     });
    //   } catch (error) {
    //     this.displayError(error);
    //   }
    // },
    async onModalClosed() {
      await this.fetchPaginatedData();
      this.visible = false;
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
          created_by: this.getLoggedInUser.id
        };
        if(this.getLoggedInUser.mess_secretary){
          tempParams["mess"] = this.getLoggedInUser.mess_secretary.mess;
        }
        else if(this.getLoggedInUser.clerk){
          tempParams["mess"] = this.getLoggedInUser.clerk.mess;
        }
        else if (this.messNameFilter) {
          tempParams["mess_name"] = this.messNameFilter;
        }
        if (this.customerNameFilter) {
          tempParams["customer_name"] = this.customerNameFilter;
        }
        if (this.customerCnicFilter) {
          tempParams["customer_cnic"] = this.customerCnicFilter;
        }
        if (this.roomNameFilter) {
          tempParams["room_name"] = this.roomNameFilter;
        }
        // if (this.hotelNameFilter) {
        //   tempParams["hotel_name"] = this.hotelNameFilter;
        // }
        if (this.cityNameFilter) {
          tempParams["city_name"] = this.cityNameFilter;
        }
        if (this.voucherNoFilter) {
          tempParams["voucher_no"] = this.voucherNoFilter;
        }
        if (this.managingHqNameFilter) {
          tempParams["managing_hq_name"] = this.managingHqNameFilter;
        }
        if (this.selectedStatusFilter) {
          tempParams["status"] = this.selectedStatusFilter.id;
        }
        if (this.selectedPaymentFilter) {
          tempParams["payment_status"] = this.selectedPaymentFilter.id;
        }
        if (this.creationDateFromFilter) {
          tempParams["created_at_from"] = this.creationDateFromFilter;
        }
        if (this.creationDateToFilter) {
          tempParams["created_at_to"] = this.creationDateToFilter;
        }
        if (this.startDateFromFilter) {
          tempParams["start_date_time_from"] = this.startDateFromFilter;
        }
        if (this.startDateToFilter) {
          tempParams["start_date_time_to"] = this.startDateToFilter;
        }
        if (this.endDateFromFilter) {
          tempParams["end_date_time_from"] = this.endDateFromFilter;
        }
        if (this.endDateToFilter) {
          tempParams["end_date_time_to"] = this.endDateToFilter;
        }
        // if (this.startDateFilter) {
        //   tempParams["start_date_time"] = this.startDateFilter;
        // }
        // if (this.endDateFilter) {
        //   tempParams["end_date_time"] = this.endDateFilter;
        // }
        if (this.noCancelledUnpaid) {
          tempParams["no_cancelled_unpaid"] = this.noCancelledUnpaid;
        }
        if (this.allPaid) {
          tempParams["all_paid"] = this.allPaid;
        }
        if(this.selectedSortByField){
          tempParams["sort_order"] = this.selectedSortingOption.value;
          tempParams["order_by"] = this.selectedSortByField.field_name;
        }
        if (this.selectedCheckoutStatus) {
          tempParams["checkout_status"] = this.selectedCheckoutStatus.id;
        }
        const res = await this.getBookings(tempParams);
        this.bookings = res.data.results;
        this.bookings = this.formatBookings(this.bookings);

        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
        this.totalDataLength = res.data.count;

      } catch (error) {
        this.displayError(error);
      }
    },
    // cancelUnpaidBooking(booking) {
    //   console.log('booking click item', booking)
    //    try {
    //      this.$swal({
    //        title: "Are you sure? This booking will be cancelled.",
    //        icon: "warning",
    //        showCloseButton: true,
    //        showCancelButton: true,
    //        confirmButtonText: "Confirm",
    //        customClass: {
    //          confirmButton: "btn btn-primary",
    //          cancelButton: "btn btn-danger ml-1",
    //        },
    //        buttonsStyling: false,
    //      })
    //    } catch (error) {
    //      this.displayError(error);
    //    }
    //  },

    async cancelPaidBooking(booking) {
      const success = await this.$refs.paymentMethodFormValidation.validate();
      if (success) {
        try {
          let temp = {};
          temp["booking_data"] = {
            booking_id: this.booking.id,
            status: 3,
            cancellation_penalty_percentage: this.booking.cancellation_charges.percentage,
            updated_by: this.getLoggedInUser.id,
          };
          temp["account_data"] = {};

          if (this.booking.status === 2 && this.booking.payment_status === 2) {
            let refundAmount =
              this.booking.total_payment -
              this.booking.cancellation_charges.amount;
            temp["booking_data"]["payment_status"] = 3;
            temp["booking_data"]["refund_amount"] =
              parseFloat(refundAmount).toFixed(2);
            temp["booking_data"]["cancellation_fee"] = parseFloat(
              this.booking.cancellation_charges.amount
            ).toFixed(2);
            // if (!this.checkType) {
            //   temp["account_data"] = {
            //     account_number: this.accountNumber,
            //     bank: this.selectedBank.id,
            //     account_title: this.accountTitle,
            //   };
            // } else {
              temp["account_data"] = {
                iban: this.iban,
                bank: this.selectedBank.id,
                account_title: this.accountTitle,
              };
            // }
          }
          const res = await this.CancelBooking({
            payload: temp,
          });
          let response_string = "  ";
          let response_msg = response_string;
          if (res.data.response_msg) {
            response_msg = response_msg.concat(res.data.response_msg);
          }

          if (
            res.status === 200 &&
            booking.status == 2 &&
            booking.payment_status == 2
          ) {
            this.$swal({
              title: "Your Refund will be processed in 7 working days.",
              icon: "success",
              footer:
                '<div>In case of any query please contact us at <br/> <div class="text-center"><strong>051-5686029</strong> and <strong>8000-37611</strong></div></div>',
            });

            this.$nextTick(() => {
              this.fetchPaginatedData();
              this.$bvModal.hide("bookingCancellation");
            });
            // this.$emit("modalClosed");
          }
        } catch (error) {
          this.show = false;
        }
      }
    },
    async earlyCheckoutConfirmation() {
      const success = await this.$refs.earlyCheckoutFormValidation.validate();
      if (success) {
        try {
            const account_data = {
              iban: this.iban,
              bank: this.selectedBank.id,
              account_title: this.accountTitle,
            };
            const payload = {
              booking_id: this.booking.id,
              account_data: account_data
            };
          const res = await this.checkoutBooking(
            payload
          );
          if ( res.status === 200) {
           this.$swal({
            title: "Checked Out",
            icon: "success",
          });
            this.$nextTick(() => {
              this.fetchPaginatedData();
              this.$bvModal.hide("earlyCheckout");
            });
          }
        } catch (error) {
          this.show = false;
        }
      }
    },
    async cancelBooking() {
      try {
        let temp = {};
        temp["booking_data"] = {
          booking_id: this.booking.id,
          status: 3,
          updated_by: this.getLoggedInUser.id,
        };
        const res = await this.CancelBooking({
          payload: temp,
        });
        if (res.status == 200) {
          this.$swal({
            title: "Booking Cancelled Successfully.",
            icon: "success",
          });

          this.$nextTick(() => {
            this.fetchPaginatedData();
            this.$bvModal.hide("bookingCancellation");
          });
        }
      } catch (error) {
        this.displayError();
      }
    },
    ConfirmCancellation(booking) {
      this.booking = booking;
      if (this.booking.status === 1 && this.booking.payment_status === 1) {
        this.$swal({
          title: "Are you sure?",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            this.cancelBooking();
          }
        });
      } else if (this.booking.status === 2 && this.booking.payment_status === 2) {
        this.booking=booking;
        this.cancellationAmount = this.booking.cancellation_charges.amount;
        this.cancellationTime = this.booking.cancellation_charges.hours_left;
        this.cancellationTime1200 = this.booking.cancellation_charges.hours_left_1200;
        this.cancellationPercentage = this.booking.cancellation_charges.percentage;
        this.showbookingCancellationModal = true;
      } else {
        this.$swal({
          title: "Booking cannot be cancelled.",
          text: "This booking is not eligible for cancellation.",
          icon: "info",
          button: "Okay",
        });
      }
    },
    resetbookingCancellationModal() {
      this.showbookingCancellationModal = false;
    },
    resetearlyCheckoutModal() {
      this.earlyCheckoutModal = false;
    },
    back() {
      this.showbookingCancellationModal = false;
    },
    goBack()
    {
      this.earlyCheckoutModal = false;
    },
    async downloadXLS(){
        this.show = true;
        await this.search();
        this.exportXls = true;
        let payload = {};
        payload['created_by'] = this.getLoggedInUser.id

      if (this.customerNameFilter) {
        payload["customer_name"] = this.customerNameFilter;
      }
      if (this.customerCnicFilter) {
        payload["customer_cnic"] = this.customerCnicFilter;
      }
      if(this.getLoggedInUser.mess_secretary){
        payload["mess"] = this.getLoggedInUser.mess_secretary.mess;
      }
      else if(this.getLoggedInUser.clerk){
        payload["mess"] = this.getLoggedInUser.clerk.mess;
      }
      else if (this.messNameFilter) {
        payload["mess_name"] = this.messNameFilter;
      }
      if (this.cityNameFilter) {
        payload["city_name"] = this.cityNameFilter;
      }
      if (this.managingHqNameFilter) {
        payload["managing_hq_name"] = this.managingHqNameFilter;
      }
      if (this.selectedStatusFilter) {
        payload["status"] = this.selectedStatusFilter.id;
      }
      if (this.selectedPaymentFilter) {
        payload["payment_status"] = this.selectedPaymentFilter.id;
      }

      if (this.creationDateFromFilter) {
        payload["created_at_from"] = this.creationDateFromFilter;
      }
      if (this.creationDateToFilter) {
        payload["created_at_to"] = this.creationDateToFilter;
      }

      if (this.startDateFromFilter) {
        payload["start_date_time_from"] = this.startDateFromFilter;
      }
      if (this.startDateToFilter) {
        payload["start_date_time_to"] = this.startDateToFilter;
      }

      if (this.endDateFromFilter) {
        payload["end_date_time_from"] = this.endDateFromFilter;
      }
      if (this.endDateToFilter) {
        payload["end_date_time_to"] = this.endDateToFilter;
      }

      // if (this.startDateFilter) {
      //   payload["start_date_time"] = this.startDateFilter;
      // }
      // if (this.endDateFilter) {
      //   payload["end_date_time"] = this.endDateFilter;
      // }

      if (this.noCancelledUnpaid) {
        payload["no_cancelled_unpaid"] = this.noCancelledUnpaid;
      }
      if (this.allPaid) {
        payload["all_paid"] = this.allPaid;
      }
      if(this.selectedSortByField){
          payload["order_by"] = this.selectedSortingOption.value + this.selectedSortByField.field_name
      }
      
      try {
        const res = await this.createExcel({
          payload: payload,
        });
        if (res.status === 200) {
          var currentdate = new Date(); 
          const url = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
           'Bookings '+ currentdate.getDate() + "-" + + (currentdate.getMonth()+1)  + "-" + currentdate.getFullYear() + " --- " + currentdate.getHours() + "-" + currentdate.getMinutes() + "-" + currentdate.getSeconds() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
          this.$swal({
            title: "Download successful",
            icon: "success",
            timer: 1500,
          });
        }
        if (res.status === 204) {
          this.$swal({
            title: "No Record Found",
            icon: "info",
          });
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      getSystemTime: "appData/getSystemTime",
    }),
    CancelButtonDisabled() {
       return !this.accountTitle || !this.selectedBank || 
        // (this.checkType && !this.accountNumber) || 
        (this.checkType && !this.iban);
    },
    EarlyCheckoutButtonDisabled() {
       return !this.accountTitle || !this.selectedBank || 
        // (this.checkType && !this.accountNumber) || 
        (this.checkType && !this.iban);
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
    allPaid(newValue, oldValue) {
      if (newValue) {
        this.selectedStatusFilter = null
        this.selectedPaymentFilter = null
      }
    },
    noCancelledUnpaid(newValue, oldValue) {
      if (newValue) {
        this.selectedStatusFilter = null
        this.selectedPaymentFilter = null
      }
    },
  },
};
//checkout when checked in
</script>
  
  <style></style>
  